@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "styles.css";

@layer components {
  .app-x-padding {
    @apply px-4 sm:px-8 md:px-12;
  }
  .app-max-width {
    @apply max-w-7xl mx-auto;
  }
}

@layer utilities {
  @variants responsive {
    .bottom-10-per {
      bottom: 10%;
    }
    .left-10-per {
      left: 10%;
    }
    .right-10-per {
      right: 10%;
    }
  }
}

.toggle-checkbox:checked {
  @apply: right-0 border-gray-500;
  right: 0;
  border-color: #282828;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-gray500;
  background-color: #282828;
}
/* @layer utilities { */
/* .carousel {
    position: relative;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
  }

  .carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
  }

  .carousel-item {
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
  }

  .carousel-item img {
    display: block;
    height: auto;
    max-width: 100%;
  }

  .carousel-control {
    background: rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
  }

  .carousel-control.prev {
    left: 2%;
  }

  .carousel-control.next {
    right: 2%;
  }

  .carousel-control:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #aaaaaa;
  }

  #carousel-1:checked ~ .control-1,
  #carousel-2:checked ~ .control-2,
  #carousel-3:checked ~ .control-3 {
    display: block;
  }

  .carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
  }

  .carousel-indicators li {
    display: inline-block;
    margin: 0 5px;
  }

  .carousel-bullet {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 35px;
  }

  .carousel-bullet:hover {
    color: #aaaaaa;
  }

  #carousel-1:checked
    ~ .control-1
    ~ .carousel-indicators
    li:nth-child(1)
    .carousel-bullet,
  #carousel-2:checked
    ~ .control-2
    ~ .carousel-indicators
    li:nth-child(2)
    .carousel-bullet,
  #carousel-3:checked
    ~ .control-3
    ~ .carousel-indicators
    li:nth-child(3)
    .carousel-bullet {
    color: #428bca;
  }

  #title {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    font-family: "Open Sans", sans-serif;
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33),
      -1px 0px 2px rgba(255, 255, 255, 0);
  } */
/* } */
/* html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
} */
