html {
  scroll-behavior: smooth;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: #282828;
  font-size: 1.5rem !important;
  font-weight: bold;
  padding: 0.5rem 0.875rem;
  border-radius: 100%;
  background-color: #ddd;
}

.swiper-button-prev::after {
  margin-left: 5rem;
}

.swiper-button-next::after {
  margin-right: 5rem;
}

@media only screen and (max-width: 769px) {
  .swiper-button-prev::after {
    margin-left: 1rem;
  }

  .swiper-button-next::after {
    margin-right: 1rem;
  }
}

/* ===== Custom Swiper pagination ===== */
.card-swiper .swiper-pagination-bullets {
  bottom: 0 !important;
}

.swiper-pagination-bullet {
  width: 1rem !important;
  border-radius: 0 !important;
  height: 0.35rem !important;
}

.swiper-pagination-bullet-active {
  background-color: #282828 !important;
}

/* .swiper-slide {
  width: 100% !important;
  margin: auto !important;
} */
